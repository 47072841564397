import {
  guaranteeDepositDialogAtom,
  incomeCheckDialogAtom,
  softCreditCheckDialogAtom,
} from 'containers/profile/recoil/atoms';
import { TaskGroupProps } from 'containers/profile/TaskGroup';
import { getTaskIdActionMap } from 'containers/profile/TasksTab';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import { atom, selector, selectorFamily, waitForAll } from 'recoil';
import { userAtom } from 'recoil/atoms';
import { sessionSelector } from 'recoil/selectors';
import Rest from 'services/rest';
import { UserTaskTypeEx } from 'shared/types/misc';
import { BidStatus } from 'shared/types/models';
import {
  CONSUMER_SERVICE_FEE_CENTS,
  TASKS_VERIFICATION_CALLBACK_PATH,
  USER_TASKS_GROUPS_MAP,
} from 'shared/utils/constants';
import { getUserTasksEx } from 'shared/utils/tasks';
import { isAuthorizedConsumerSelector } from 'containers/deal/recoil/selectors';
import {
  checkWinningBidStatusPredicate,
  WINNING_BID_AUCTION_STATUSES,
} from '../../../shared/utils/getWinningBid';

const rest = new Rest();

export const userVehiclesSelector = selector({
  key: 'USER_VEHICLES',
  get: async ({ get }) => {
    if (!get(isAuthorizedConsumerSelector)) {
      return [];
    }

    return rest.getUserVehicles();
  },
});

// useRecoilRefresher_UNSTABLE breaks the page build in this case
export const forceRefreshUserPaymentCapturableSelector = atom({
  key: 'FORCE_REFRESH_USER_PAYMENT_CAPTURABLE',
  default: 0,
});

export const userPaymentCapturableSelector = selector({
  key: 'USER_PAYMENT_CAPTURABLE',
  get: async ({ get }) => {
    get(forceRefreshUserPaymentCapturableSelector);
    if (!get(isAuthorizedConsumerSelector)) {
      return false;
    }

    const auctionId = get(userWinningBids)[0]?.auction_id;

    if (!auctionId) {
      return false;
    }

    return rest.isPaymentCapturable(auctionId).catch(() => false);
  },
});

export const userCosignersSelector = selector({
  key: 'USER_COSIGNERS',
  get: async ({ get }) => {
    if (!get(isAuthorizedConsumerSelector)) {
      return [];
    }
    return rest.getUserCosigners();
  },
});

export const userCosignerUpdateAllowedSelector = selector({
  key: 'USER_COSIGNER_UPDATE_ALLOWED_SELECTOR',
  get: ({ get }) => {
    if (!get(isAuthorizedConsumerSelector)) {
      return;
    }

    return rest.getIsCosignerUpdateAllowed();
  },
});

export const userBidsSelector = selector({
  key: 'USER_BIDS',
  get: async ({ get }) => {
    if (!get(isAuthorizedConsumerSelector)) {
      return [];
    }

    return rest.getUserBids();
  },
});

export const userWinningBids = selector({
  key: 'WINNING_BIDS',
  get: async ({ get }) => {
    const bids = get(userBidsSelector);

    const filteredBids = bids.filter((bid) =>
      WINNING_BID_AUCTION_STATUSES.includes(bid.auction.status),
    );
    const bidsGroupedByAuction = groupBy(filteredBids, 'auction_id');
    const winningBids = reduce(
      bidsGroupedByAuction,
      (accumulator, userBids) => {
        const auctionBids = userBids?.[0].auction.bids || [];
        const sortedBids = sortBy(auctionBids, ['id']);
        const highestBid = sortedBids[sortedBids.length - 1];
        const isBidOwner = highestBid.user_id === userBids?.[0].user_id;
        if (checkWinningBidStatusPredicate(highestBid?.status) && isBidOwner) {
          accumulator.push(highestBid);
        }
        return accumulator;
      },
      [],
    );

    return winningBids;
  },
});

export const userTasksSelector = selector<UserTaskTypeEx[]>({
  key: 'USER_TASKS',
  get: async ({ get }) => {
    if (typeof window === 'undefined') {
      return [];
    }

    if (!get(isAuthorizedConsumerSelector)) {
      return [];
    }

    const [
      vehicles,
      cosigners,
      winningBids,
      session,
      user,
      userPaymentCapturable,
    ] = get(
      waitForAll([
        userVehiclesSelector,
        userCosignersSelector,
        userWinningBids,
        sessionSelector({ callbackPath: TASKS_VERIFICATION_CALLBACK_PATH }),
        userAtom,
        userPaymentCapturableSelector,
      ]),
    );

    const hasWonAuctions = winningBids.length > 0;

    const cosignerNeeded = winningBids.some(
      (bid) => bid.status === BidStatus.CosignerNeeded,
    );

    const userTasks = getUserTasksEx({
      user,
      cosigners,
      vehicles,
      session,
      hasWonAuctions,
      cosignerNeeded,
      userPaymentCapturable,
    });

    return userTasks;
  },
});

export const userTaskGroupsSelectorFamily = selectorFamily<
  TaskGroupProps[],
  {
    pathnameQueryString: string;
  }
>({
  key: 'USER_TASKS_CARDS',
  get:
    ({ pathnameQueryString }) =>
    async ({ get, getCallback }) => {
      if (!get(isAuthorizedConsumerSelector)) {
        return [];
      }

      const user = get(userAtom);
      const tasks = get(userTasksSelector);

      const openIncomeCheckDialog = getCallback(({ set }) => () => {
        set(incomeCheckDialogAtom, true);
      });

      const openSoftCreditCheckDialog = getCallback(({ set }) => () => {
        set(softCreditCheckDialogAtom, true);
      });

      const openGuaranteeDepositDialog = getCallback(({ set }) => () => {
        set(guaranteeDepositDialogAtom, true);
      });

      const handlers = {
        onSoftIncomeCheck: openIncomeCheckDialog,
        onSoftCreditCheck: openSoftCreditCheckDialog,
        onGuaranteeDeposit: openGuaranteeDepositDialog,
      };

      const taskIdActionMap = getTaskIdActionMap({
        user,
        handlers,
        pathnameQueryString,
      });

      return USER_TASKS_GROUPS_MAP.map((taskGroupMapItem) => {
        const filteredByGroupTasksEx = tasks.filter((taskEx) =>
          taskGroupMapItem.tasksIds.includes(taskEx.id),
        );

        const userTasksProps = filteredByGroupTasksEx.map((taskEx) => {
          const taskAction =
            taskIdActionMap.find((mapItem) => mapItem.taskId === taskEx.id)
              ?.action || null;
          return {
            ...taskEx,
            action: taskAction,
          };
        });
        return (
          userTasksProps.length > 0 && {
            title: taskGroupMapItem.name,
            tasks: userTasksProps,
          }
        );
      }).filter(Boolean);
    },
});

export const userServiceFeeSelector = selector({
  key: 'USER_SERVICE_FEE',
  get: async ({ get }) => {
    if (!get(isAuthorizedConsumerSelector)) {
      return CONSUMER_SERVICE_FEE_CENTS;
    }

    return rest.getUserServiceFee();
  },
});

export const userReferralsSelector = selector({
  key: 'USER_REFERRALS',
  get: async ({ get }) => {
    if (!get(isAuthorizedConsumerSelector)) {
      return [];
    }

    return rest.getUserReferrals();
  },
});

export const userRewardBalanceSelector = selector({
  key: 'USER_REWARD_BALANCE',
  get: async ({ get }) => {
    if (!get(isAuthorizedConsumerSelector)) {
      return [];
    }

    return rest.getUserRewardBalance();
  },
});
